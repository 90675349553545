import { BaseService } from 'core/shared/base.service';
import { Injectable } from '@angular/core';
import { FeedbackCategory } from 'pages/misc/feedback/shared/feedback-category.model';
import { BaseResponse } from 'core/shared/base-response.interface';
import { Feedback } from 'pages/misc/feedback/shared/feedback.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService extends BaseService {

    protected url: string = 'api/feedbacks';

    /**
     * `POST /api/feedbacks`
     * 
     * Store Feedback
     */
    public async store(feedback: Feedback): Promise<Feedback> {
        return super.handleStore(feedback).then(
            (response: BaseResponse) => new Feedback(response.data.feedback)
        );
    }

    /**
     * `GET /api/feedback-categories`
     * 
     * Get FeedbackCategories
     */
    public async getFeedbackCategories(): Promise<FeedbackCategory[]> {
        return super.handleBaseResponse(
            this.http.get(`${this.apiUrl}/api/feedback-categories`).toPromise()
        ).then(
            (response: BaseResponse) => response.data.feedbackCategories.map(
                (feedbackCategory) => new FeedbackCategory(feedbackCategory)
            )
        );
    }
}
