import { Component } from '@angular/core';
import { 
	Event, 
	NavigationCancel, 
	NavigationEnd, 
	NavigationError, 
	NavigationStart, 
	Router 
} from '@angular/router';
import { LoaderService } from 'core/shared/loader.service';



@Component({
	selector: 'route-progress-bar',
	templateUrl: './route-progress-bar.component.html',
	styleUrls: ['./route-progress-bar.component.scss'],
})
export class RouteProgressBar {

	public error: boolean = false;

	constructor(
		private router: Router,
		public loader: LoaderService,
	) {
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loader.loading = true;
					break;
				}

				case event instanceof NavigationCancel:
				case event instanceof NavigationEnd: {
					this.loader.loading = false;
					break;
				}
				case event instanceof NavigationError: {
					this.error = true;
					this.loader.loading = false;
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	get color() {
		if (this.error) {
			return 'danger';
		}

		return 'prestes-green';
	}
}

