import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './server-error.page.html',
})
export class ServerErrorPage implements OnInit {
    info: Record<string, string> = {
        title: 'Erro de servidor',
        status: '500',
        message: 'Internal Server Error',
      };

    constructor(
    ) {}

    ngOnInit() {
    }

}
