export class Serializable {

    public id: any = null;

    constructor() {}

    public serialize(data: Object = {}, includeNullValues: boolean = false, callback: any = null) {
        if (!data) {
            return;
        }

        var keys: Array<string> = Object.keys(data);

        keys.forEach ((property, index) => {
            if (includeNullValues) {
                if (data[property] === undefined) {
                    return;
                }
            } else {
                if (data[property] === null || data[property] === undefined) {
                    return;
                }
            }

            if (this.propertyIsDeclared(property)) {
                if (this.relationshipExists(property)) {
                    this.setRelationship(property, data[property]);
                } else {
                    this.set(property, data[property]);
                }
            }

            if (index == keys.length -1) {
                if (callback) {
                    callback(this);
                }
            }
        }, this);
    }

    protected propertyIsDeclared(property: string) {
        return !!Object.getOwnPropertyDescriptor(this, property);
    }

    protected relationshipExists(property: string) {
        return (Object.keys(this.relationships).indexOf(property) > -1) && (this.relationships[property] !== null);
    }

    protected set(property: string, value: any) {
        this[property] = value;
    }

    protected setRelationship(property: string, value: any) {

        var constructor = this.relationships[property];

        if (Array.isArray(value)) {
            this[property] = value.map ( item => new constructor(item) );
        } else {
            this[property] = new constructor(value);
        }
    }

    /**
     * Define o tipo de propriedades que devem ser serializadas
     * recursivamente, segundo exemplo:
     *
     * user: User
     *
     * A propriedade "user" será usada para instanciar uma nova classe "User".
     *
     * @return Object
     */
    protected get relationships(): Object {
        return {};
    }

    /**
     * Retorna dados que serão enviados para o servidor
     * a fim de persistência no banco de dados
     *
     * @return Object
     */
    public get httpData(): Object {
        return {};
    }
}
