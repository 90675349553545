import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; //importado validators e formGroup
import { ModalController } from '@ionic/angular';
import { ToastService } from 'core/shared/toast.service';
import { FeedbackCategory } from 'pages/misc/feedback/shared/feedback-category.model';
import { Feedback } from 'pages/misc/feedback/shared/feedback.model';
import { FeedbackService } from 'pages/misc/feedback/shared/feedback.service';

@Component({
    templateUrl: './create.modal.html',
    styleUrls: ['./create.modal.scss'],
})
export class CreateModal implements OnInit {

    public feedback: Feedback = new Feedback();

    public loading: boolean = false;

    public fGroup: FormGroup;

    public feedbackCategories: Array<FeedbackCategory> = [];

    constructor(
        private service: FeedbackService,
        private modalController: ModalController,
        private toastService: ToastService,
        private fBuilder: FormBuilder) {

        this.fGroup = this.fBuilder.group({
            description: [this.feedback.description, Validators.compose(
                [
                    Validators.required, // validação se o campo está preenchido (item obrigatório)
                    Validators.maxLength(500) // limite máximo de 500 caracteres para o feedback
                ]
            )],
            category: [this.feedback.feedbackCategory, Validators.compose(
                [Validators.required] // validação se o campo está preenchido (item obrigatório)
            )],
        });
    }

    ngOnInit() {
        //
    }

    ionViewWillEnter() {
        this.feedback = new Feedback();
        this.getFeedbackCategories();
    }

    public get description() {
        return this.fGroup.get('description');
    }

    public get category() {
        return this.fGroup.get('category');
    }


    public async dismiss() {
        await this.modalController.dismiss();
    }

    public async getFeedbackCategories() {
        if (!this.feedbackCategories.length) {
            this.feedbackCategories = await this.service.getFeedbackCategories();
        }
    }

    public save() {
        if (this.fGroup.invalid) {
            if (this.category.invalid) {
                if (this.category.errors?.required) {
                    this.toastService.present('Por favor selecione uma categoria.', 'warning');
                    return;
                }
            }
            if (this.description.invalid) {

                if (this.description.errors?.required) {
                    this.toastService.present('Por favor insira sua mensagem.', 'warning');
                    return;
                }
                if (this.description.errors?.maxlength) {
                    this.toastService.present('A mensagem deve conter até 500 caracteres.', 'warning');
                    return;
                }
            }
        }

        this.service.store(this.feedback)
            .then(() => this.dismiss());
    }

}



