import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-prestes-card',
  templateUrl: './prestes-card.component.html',
  styleUrls: ['./prestes-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrestesCardComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() type?: string = null;
  size: Record<string, string>;
  @Input() background: boolean = true;


  constructor() { }

  ngOnInit() {
    this.size = {
      width: this.width,
      height:this.height
    };
  }

}
