import { Injectable } from '@angular/core';
import { BaseService } from 'core/shared/base.service';
import { BaseResponse } from 'core/shared/base-response.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService extends BaseService {

    protected url: string = 'api/auth';

    /**
     * `POST /auth`
     *
     * Authenticate
     */
    public async authenticate(email: string, password: string): Promise<BaseResponse> {
        return super.handleBaseResponse(
            this.http.post(this.buildUrl('/admin'), {
                email:    email,
                password: password,
            }).toPromise()
        );
    }

    /**
     * `POST /auth/logout
     *
     * Log Out
     */
    public async logOut(): Promise<BaseResponse> {
        return super.handleBaseResponse(
            this.http.post(this.buildUrl('/logout'), null).toPromise()
        );
    }

    /**
     * `POST /auth/send-password-reset`
     *
    * Send Password Reset
     */
    public sendPasswordReset(email: string) {
        return this.http.post(this.buildUrl('/send-password-reset'), {
            email: email,
        }).toPromise();
    }

    /**
     * `POST /auth/update-password`
     *
     * Update Password
     */
    public insertPassword(email: string, token: string, password: string, password_confirmation: string) {
        return this.http.put(this.buildUrl('/update-password'), {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation,
        }).toPromise();
    }
}
