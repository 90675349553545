import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    async canActivate(): Promise<boolean | UrlTree> {
        const authenticated = await this.auth.checkAuth();
        
        if (authenticated) {
            return this.router.parseUrl(this.auth.user.defaultPage);
        }
        
        return this.router.parseUrl('/login');
    }
}
