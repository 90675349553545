import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { RouteProgressBar } from 'core/components/route-progress-bar/route-progress-bar.component';
import { IonBaseSearch } from 'core/components/ion-base-search/ion-base-search.component';
import { PrestesCardComponent } from 'core/components/prestes-card/prestes-card.component';
import { TooltipDirective } from 'core/directives/tooltip/tooltip.directive';
import { IndexPage } from 'core/components/pages/index/index.page';
import { BasePage } from 'core/components/pages/base/base.page';
import { ScrollbarThemeModule } from 'core/directives/scrollbar-theme.directive';
import { SideMenu } from 'core/components/side-menu/side-menu.component';
import { RouterModule } from '@angular/router';
import { DroppableFileInput } from 'core/components/droppable-file-input/droppable-file-input.component';
import { DroppableDirective } from 'core/directives/droppable.directive';
import { CurrencyMaskDirective } from 'core/directives/currency-mask.directive';
import { PlateMaskDirective } from 'core/directives/plate-mask.directive';
import { MaskedInputComponent } from 'core/components/masked-input/masked-input.component';
import { MultipleDroppableFileInput } from 'core/components/multiple-droppable-file-input/multiple-droppable-file-input.component';
import { TooltipComponent } from 'core/components/tooltip/tooltip.component';
import { InfoTooltipDirective } from 'core/directives/info-tooltip/info-tooltip.directive';
import { PercentageMaskDirective } from 'core/directives/percentage-mask.directive';
import { WelcomeComponent } from 'core/components/welcome/welcome.page';
import { PieChartComponent } from 'core/components/charts/pie-chart/pie-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [
        RouteProgressBar,
        IonBaseSearch,
        DroppableFileInput,
        MultipleDroppableFileInput,
        PrestesCardComponent,
        TooltipDirective,
        IndexPage,
        BasePage,
        SideMenu,
        DroppableDirective,
        CurrencyMaskDirective,
        PlateMaskDirective,
        PercentageMaskDirective,
        MaskedInputComponent,
        TooltipComponent,
        InfoTooltipDirective,
        WelcomeComponent,
        PieChartComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        IonicSelectableModule,
        ScrollbarThemeModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
    ],
    exports: [
        CommonModule,
        IonicModule,
        RouteProgressBar,
        IonBaseSearch,
        DroppableFileInput,
        MultipleDroppableFileInput,
        PrestesCardComponent,
        TooltipDirective,
        IndexPage,
        BasePage,
        SideMenu,
        DroppableDirective,
        CurrencyMaskDirective,
        PlateMaskDirective,
        PercentageMaskDirective,
        MaskedInputComponent,
        TooltipComponent,
        InfoTooltipDirective,
        WelcomeComponent,
        PieChartComponent
    ],
})
export class BaseModule {}
