import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from 'core/components/pages/shared/tab.model';

@Component({
    selector: 'base-page',
    templateUrl: './base.page.html',
    styleUrls: ['./base.page.scss'],
})
export class BasePage {
    //Header
    @Input('pageTitle') public pageTitle: string = null;
    @Input('backButton') public backButton?: string = null;

    public setPageTitle(title: string) {
        this.pageTitle = title;
    }

    //Content
    public loading: boolean = true;

    //Footer
    @Input('tabs') public tabs: Tab[] = [];
    @Output('onChangeTab') public onChangeTab: EventEmitter<Tab>;
    public selectedTab: string = null;

    public setTabs(tabs: Tab[] = []) {
        this.tabs = tabs;
        this.selectedTab = this.tabs.length ? this.tabs[0].value : null;
    }

    public tabIs(tabName: string): boolean {
        return this.selectedTab === tabName;
    }
}
