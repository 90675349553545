export { }

declare global {
    interface Array<T> {
        unique(): Array<T>;
    }
}

Array.prototype.unique = function() {
    return [...new Set(this)];
}