import { Attribute, Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appPlateMask]',
})
export class PlateMaskDirective {
    constructor(private el: ElementRef, private control: NgControl) {}

    @HostListener('input', ['$event'])
    onInputChange(event: Event) {
        const input = event.target as HTMLInputElement;
        const value = input.value.toUpperCase();

        const cleanValue = value.replace(/[^A-Z0-9]/g, '');

        let formattedValue = '';
        let firstPart = '';
        let secondPart = '';

        for (let i = 0; i < cleanValue.length; i++) {
            if (i < 4 && /^[A-Z]$/.test(cleanValue[i])) {
                firstPart += cleanValue[i];
            } else if (i >= 4 && /^[0-9]$/.test(cleanValue[i])) {
                secondPart += cleanValue[i];
            }
        }

        if (firstPart.length > 0) {
            formattedValue += firstPart;
        }

        if (secondPart.length > 0) {
            formattedValue += '-';
            formattedValue += secondPart.substring(0, 7);
        }

        input.value = formattedValue;

        this.control.viewToModelUpdate(formattedValue);
    }
}
