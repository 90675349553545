import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PersonAvatar } from './person-avatar';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    exports: [
        PersonAvatar
    ],
    declarations: [
        PersonAvatar
    ],
})
export class PersonAvatarModule { }
