import { Component, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnChanges, AfterViewInit {
  @Input('data') data: any = [];
  @Input('name') name: string = '';

  private prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');
  chartOptions: EChartsOption = null;
  chartInstance: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.name) {
      this.updateChartOptions();
    }
  }

  ngAfterViewInit() {
    if (this.chartInstance) {
      // Check container dimensions before resizing
      const container = document.querySelector('div[echarts]');
      if (container && container.clientWidth > 0 && container.clientHeight > 0) {
        this.chartInstance.resize();
      } else {
        console.warn('Chart container has zero dimensions. Consider checking CSS or waiting for DOM to fully render.');
      }
    }
  }

  updateChartOptions() {
    this.chartOptions = {
      darkMode: 'auto',
      title: {
        text: this.name,
        left: 'center',
        textStyle: {
          color: '#84e115',
        },
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: '#FFF',
        },
      },
      series: [
        {
          name: this.name,
          type: 'pie',
          radius: '50%',
          data: this.data,
          label: {
            color: '#84e115',
            show: false,
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  onChartInit(chart) {
    this.chartInstance = chart;
    setTimeout(() => {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    }, 0);

    window.addEventListener('resize', () => {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    });
  }
}
