import { Serializable } from "core/shared/serializable.model";

export class MenuPage extends Serializable {
    public title: string = null;
    public subtitle: string = null;
    public url: string = null;
    public icon: string = null;
    public permission: string = null;
    public default: boolean = null;
    public showDetails: boolean = null;
    public subPages: MenuPage[] = [];

    public constructor(data: any = {}) {
        super();
        this.serialize(data);
        this.setIcon();
    }

    public get relationships() {
        return { subPages: MenuPage };
    }

    private setIcon() {
        if (this.subPages.length) {
            this.icon = 'ios-arrow-down';
        }
    }

    public toggle() {
        this.showDetails = !this.showDetails;

        if (this.showDetails) {
            this.icon = 'ios-arrow-up';
        } else {
            this.icon = 'ios-arrow-down';
        }
    }
}
