import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BaseModule } from 'core/shared/base.module';
import { NotFoundPage } from 'pages/misc/errors/not-found.page';
import { ServerErrorPage } from 'pages/misc/errors/server-error.page';
import { PermissionDeniedPage } from 'pages/misc/errors/permission-denied.page';
import { ErrorComponent } from 'pages/misc/errors/components/error-page/error.component';

const routes: Routes = [
  {
    path: '403',
    component: PermissionDeniedPage,
    data: {
        title: '403 - Permissões Insuficientes'
    }
  },
  {
    path: '500',
    component: ServerErrorPage,
    data: {
        title: '500 - Erro Inesperado'
    }
},
{
    path: '**',
    component: NotFoundPage,
    data: {
        title: '404 - Página Não Encontrada'
    }
},
];

@NgModule({
  declarations: [
    ErrorComponent,
    NotFoundPage,
    ServerErrorPage,
    PermissionDeniedPage
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    BaseModule,
    RouterModule.forChild(routes),

  ],
  exports: [
    ErrorComponent
  ],
})
export class ErrorModule { }
