import jwtDecode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {

    private cookieName: string = 'pv_session';

    public token: string;

    constructor(
        private cookieService: CookieService
    ) {
        this.setInitialtoken();
    }

    public setCookie(token: string) {
        this.setToken(token);

        this.cookieService.set(
            this.cookieName, 
            this.token, 
            this.expiresIn
        );
    }

    public deleteCookie() {
        this.token = null;
        this.cookieService.delete(this.cookieName);
    }

    public get isValid() {
        if (!this.token) {
            return false;
        }

        return !this.isExpired;
    }

    private setInitialtoken() {
        const cookie = this.getCookie();

        if (cookie) {
            this.setToken(cookie);
        }
    }

    private getCookie(): string {
        return this.cookieService.get(this.cookieName);
    }

    private setToken(token: string) {
        this.token = token;
    }

    private get decodedToken(): any {
        return jwtDecode(this.token);
    }

    private get expiresIn() {
        return DateTime.fromMillis(this.decodedToken.exp * 1000).toJSDate();
    }

    private get isExpired() {
        return DateTime.local().toMillis() >= DateTime.fromMillis(this.decodedToken.exp * 1000);
    }
}