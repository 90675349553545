import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'masked-input',
    templateUrl: './masked-input.component.html',
    styleUrls: ['./masked-input.component.scss'],
})
export class MaskedInputComponent {
	@Input('model') model?: any = null;
    @Input() type: 'currency' | 'plate' | 'percentage';
    @Input() label?: string = null;
    @Input() disabled?: boolean = false;
    @Input() required?: boolean = false;
    public inputIsClicked: boolean = false;

    @Output('modelChange') modelChange: EventEmitter<any> = new EventEmitter<any>();
	@Output('onChange') onChange = new EventEmitter<any>();


    public toggleLabelColor() {
        this.inputIsClicked = !this.inputIsClicked;
    }

    public change(event) {
        this.onChange.emit(event);
		this.modelChange.emit(event);
    }
}
