import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './not-found.page.html',
})
export class NotFoundPage implements OnInit {
  info: Record<string, string> = {
    title: 'Página não encontrada',
    status: '404',
    message: 'Not Found',
  };

  constructor() {}

  ngOnInit() {}
}
