import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localePt from '@angular/common/locales/pt';
import { ThemeSwitcherModule } from 'core/components/theme-switcher/theme-switcher.module';
import { ScrollbarThemeModule } from 'core/directives/scrollbar-theme.directive';
import { AuthInterceptor } from 'pages/account/auth/shared/auth.interceptor';
import { DocPipe } from 'core/pipes/doc';
import { ToastService } from 'core/shared/toast.service';
import { BaseModule } from 'core/shared/base.module';
import './core/shared/custom-methods';
import { CreateModal } from 'pages/misc/feedback/create/create.modal';
import { ErrorModule } from 'pages/misc/errors/shared/error.module';
import { PersonAvatarModule } from 'pages/account/users/components/person-avatar/person-avatar.module';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        CreateModal
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ThemeSwitcherModule,
        ErrorModule,
        ScrollbarThemeModule,
        PersonAvatarModule,
        BaseModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    providers: [
        DocPipe,
        ToastService,
        {
            provide: LOCALE_ID, useValue: 'pt-BR'
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]

})

export class AppModule {}
