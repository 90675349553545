import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[infoTooltip]',
})
export class InfoTooltipDirective {

    @Input() text = '';

    constructor(private el: ElementRef) { }


    @HostListener('mouseenter') onMouseEnter() {
        setTimeout(() => {
            let x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2 + 95; // Get the middle of the element
            let y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight - 140; // Get the bottom of the element, plus a little extra
            this.createTooltipPopup(x, y);
        });
    }

    private createTooltipPopup(x: number, y: number) {
        let itemTooltip = document.createElement('div');
        let tooltipInfo = document.createElement('div');
        let tooltipContent = document.createElement('div');
        let tooltipRadius = document.createElement('div');
        let tooltipContentText = document.createElement('p');


        itemTooltip.append(tooltipInfo);
        tooltipInfo.append(tooltipContent);
        tooltipInfo.append(tooltipRadius);
        tooltipContent.append(tooltipContentText);

        let tooltip = this.text;

        tooltipContentText.innerHTML = tooltip;

        itemTooltip.setAttribute("class", "item-tooltip");
        tooltipInfo.setAttribute("class", "tooltip-info");
        tooltipRadius.setAttribute("class", "tooltip-radius");
        tooltipInfo.setAttribute("data-position", "4");
        tooltipContent.setAttribute("class", "tooltip-content");
        tooltipContentText.setAttribute("class", "item-content-text");

        itemTooltip.style.top = y.toString() + 'px';
        itemTooltip.style.left = x.toString() + 'px';

        document.body.appendChild(itemTooltip);

        setTimeout(() => {
            itemTooltip.remove();
        }, 5000);
    }

}
