import { NgModule, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
    constructor(el: ElementRef) {
        const stylesheet = `
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        
        ::-webkit-scrollbar-thumb {
            background: rgba(var(--ion-color-prestes-green-rgb));
            border-radius: 12px;
            border: 2px solid transparent;
            background-clip: padding-box; // <== make the border work
        }
        
        ::-webkit-scrollbar-thumb:hover{
            border: 0;
        }
        
        ::-webkit-scrollbar-track {
            background: transparent;
        }
    `;

        if (el.nativeElement.shadowRoot) {
            var elmt = el.nativeElement.shadowRoot
        } else {
            var elmt = el.nativeElement;
        }

        const styleElmt = elmt.querySelector('style');

        if (styleElmt) {
            styleElmt.append(stylesheet);
        } else {
            const barStyle = document.createElement('style');
            barStyle.append(stylesheet);
            elmt.appendChild(barStyle);
        }

    }
}


@NgModule({
    declarations: [ScrollbarThemeDirective],
    exports: [ScrollbarThemeDirective]
})
export class ScrollbarThemeModule { }
