import {
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ToastService } from 'core/shared/toast.service';
import { File } from 'pages/misc/files/shared/file.model';

@Component({
    selector: 'droppable-file-input',
    templateUrl: './droppable-file-input.component.html',
    styleUrls: ['./droppable-file-input.component.scss'],
})
export class DroppableFileInput implements OnChanges {
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    @Output() filesEmitter = new EventEmitter();
    @Output() fileDeleteEmitter = new EventEmitter();
    @Input() currentFile: File = null;
    @Input('acceptedTypes') public acceptedTypes: Array<string> = ['png', 'jpg', 'jpeg', 'pdf', 'xlsx'];

    file: File = null;

    constructor(
        public toast: ToastService
    ) {}

    onKey(event) {
        event.preventDefault();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.currentFile) {
            this.file = this.currentFile;
        }
    }

    onFileDropped($event) {
        this.prepareFile($event);
    }

    fileBrowseHandler($event) {
        this.prepareFile($event.target.files);
    }

    deleteFile() {
        this.file = null;
        this.fileDeleteEmitter.emit(this.file);
    }

    prepareFile(files: Array<any>) {
        if (files.length === 0) {
            return;
        }

        const fileType = files[0].name.split('.');

        if (!this.acceptedTypes.includes(fileType[fileType.length - 1])) {
            this.toast.warning(`O tipo ${fileType[fileType.length - 1]} não é permitido. Os tipos permitidos são: ${this.acceptedTypes.join(', ')}`);
            return 0;
        }

        if (files[0].size > 10485760) {
            this.toast.warning("Só são permitidos arquivos com até 10MB.");
            return 0;
        }

        this.file = null;
        const file = new File({
            name: files[0].name,
            size: files[0].size,
            file: files[0],
            mimeType: files[0].type,
        });

        this.file = file;

        this.filesEmitter.emit(this.file);
        this.fileDropEl.nativeElement.value = null;
    }

    formatFilename(filename: string): string {
        if (!filename) {
          return '';
        }

        const limit = 30;

        const extension = filename.split('.').pop();

        const truncatedFilename = filename.substring(0, limit);

        return `${truncatedFilename}... .${extension}`;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
}
