import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    public loader: any;

    public loading: boolean = false;

    public value: any;

    constructor(
        private loadingController: LoadingController
    ) { }

    async present(message: string = 'Carregando...') {
        this.loader = await this.loadingController.create({
            message: message,
        });

        await this.loader.present();
    }

    async checkAndCloseLoader() {
        // Use getTop function to find the loader and dismiss only if loader is present.
        const loader = await this.loadingController.getTop();
        // if loader present then dismiss
        if (loader !== undefined) {
            await this.loadingController.dismiss();
        }
    }

    async dismiss() {
        this.checkAndCloseLoader();

        // sometimes there's delay in finding the loader. so check if the loader is closed after one second. if not closed proceed to close again
        setTimeout(() => this.checkAndCloseLoader(), 500);
    }
}