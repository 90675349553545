import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-theme-switcher',
	templateUrl: './theme-switcher.component.html',
	styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit, OnChanges {
	themePreference: string = localStorage.getItem('color-theme') || 'dark';

	constructor() { }

	ngOnInit() {
		this.setTheme();
	}

	ngOnChanges(changes: SimpleChanges) {
	};

	setTheme() {
		const checkbox = document.getElementById('slider') as HTMLInputElement;

		if (this.themePreference === 'dark') {
			document.body.setAttribute('color-theme', 'dark');
		} else {
			document.body.setAttribute('color-theme', 'light');
			checkbox.checked = true;
		}
	}

	switchTheme() {
		if (this.themePreference === 'dark') {
			this.themePreference = 'light';
			localStorage.setItem('color-theme', this.themePreference);
		} else {
			this.themePreference = 'dark';
			localStorage.setItem('color-theme', this.themePreference);
		}
		this.ngOnInit();
	}
}
