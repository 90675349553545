import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { SessionService } from 'pages/account/auth/shared/session-service';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        req = req.clone({
            url: req.url,
            setHeaders: {
                Authorization: 'Bearer ' + this.auth.session.token
            },
        });

        return next.handle(req).pipe(tap(() => { },
        (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status == 401) {
                    this.auth.killSession();
                    this.router.navigate(['/login']);
                }

                if (err.status == 403) {
                    this.router.navigate(['/403']);
                }
            }
        }));
    }
}