import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toastController: ToastController) {}

    public async present(message: string, type: string = 'primary', duration = 3500): Promise<void> {
        const active = await this.toastController.getTop();

        if (active) {
            await active.dismiss();
        }
        
        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            color: type,
            position: 'top',
        });

        return toast.present();
    }

    public async warning(message: string, duration = 3500): Promise<void> {
        return this.present(message, 'warning', duration);
    }

    public async success(message: string, duration = 3500): Promise<void> {
        return this.present(message, 'success', duration);
    }

    public async danger(message: string, duration = 3500): Promise<void> {
        return this.present(message, 'danger', duration);
    }

    public async httpToast(response: any) {
        if (response instanceof HttpErrorResponse) {
            return this.presentHttpError(response);
        }

        return this.presentHttpSuccess(response);
    }

    private presentHttpError(httpError: HttpErrorResponse) {
        if (httpError.status == 500) {
            return this.danger(httpError.error.message);
        }

        return this.warning(httpError.error.message);
    }

    private presentHttpSuccess(response: any) {
        if (response.message) {
            return this.present(response.message,  response.error ? 'success' : 'warning');
        }

        return this.present('Ação realizada com sucesso.', response.error ? 'success' : 'warning');
    }

    public async defaultErrorMessage() {
        return this.danger(
            'Ocorreu um problema inesperado, por favor tente novamente. \
            Caso o problema persista contate a equipe de desenvolvimento.'
        );
    }
}
