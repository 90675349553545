import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'ion-base-search',
	templateUrl: './ion-base-search.component.html',
	styleUrls: ['./ion-base-search.component.scss'],
})
export class IonBaseSearch {

	@Input('disabled') disabled?: boolean = false;
	@Input('ngIf') ngIf?: boolean = true;
	@Input('model') model?: any = null;
	@Input('items') items?: any;
	@Input('disabledItems') disabledItems?: any;
	@Input('name') name?: string;
	@Input('itemValueField') itemValueField?: string = 'id';
	@Input('itemTextField') itemTextField?: string = 'name';
	@Input('canSearch') canSearch?: boolean = true;
	@Input('isMultiple') isMultiple?: boolean = false;
	@Input('canClear') canClear?: boolean = true;
	@Input('placeholder') placeholder?: string = 'Selecione...';
	@Input('searchPlaceholder') searchPlaceholder?: string = 'Pesquisar...';
	@Input('clearButtonText') clearButtonText?: string = 'Limpar Filtros';
	@Input('closeButtonText') closeButtonText?: string = 'Fechar';
	@Input('searchFailText') searchFailText?: string = 'Nenhum registro encontrado.';
    @Input('hasDescription') hasDescription?: boolean = false;
	@Output('onChange') onChange = new EventEmitter<any>();
	@Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    public change(event) {
		this.modelChange.emit(event.value);
        this.onChange.emit(event);
    }

	onKey(event) {
        event.preventDefault();
    }
}

