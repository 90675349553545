import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appPercentageMask]'
})
export class PercentageMaskDirective {
    constructor(private el: ElementRef, private control: NgControl) {}

    @HostListener('input', ['$event'])
    onInputChange(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value.trim();

        value = value.replace(',', '.');

        value = value.replace(/[^\d.]/g, '');

        const decimalRegex = /^\d+(\.\d{0,2})?$/;
        if (decimalRegex.test(value)) {
            input.value = value;
        } else {
            input.value = '';
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = parseFloat(input.value);

        if (isNaN(value) || value < 1) {
            value = 1;
        } else if (value > 100) {
            value = 100;
        }

        if (!isNaN(value)) {
            input.value = value.toFixed(2) + '%';
        }

        this.control.viewToModelUpdate(value / 100);
    }
}
