import { Component, ViewChild, AfterViewChecked } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { AuthService } from "pages/account/auth/shared/auth.service";

@Component({
	templateUrl: './welcome.page.html',
	styleUrls: ['./welcome.page.scss'],
	selector: 'welcome',
})

export class WelcomeComponent implements AfterViewChecked {

	constructor (
		public auth: AuthService
	) {}

    ngAfterViewChecked() {
        this.updateLogo();
      }

      private updateLogo() {
        const img = document.querySelector('#welcome-image img');
        if (img) {
          const updatedSrc = this.verifyThemeForIcon();
          if (img.getAttribute('src') !== updatedSrc) {
            img.setAttribute('src', updatedSrc);
          }
        }
      }

	verifyThemeForIcon(): string {
		return document.body.getAttribute('color-theme') === 'light'
		? '../../assets/logo-light.png' :
		  '../../assets/logo-dark.png';
	}

}
