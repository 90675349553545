import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Component({
    selector: 'side-menu',
    templateUrl: 'side-menu.component.html',
    styleUrls: ['side-menu.component.scss']
})
export class SideMenu implements OnInit {

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }
}
