import { IPersonAvatar } from './person.avatar.interface';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'person-avatar',
    templateUrl: './person-avatar.html',
    styleUrls: ['./person-avatar.scss'],
    providers: [NavParams]
})
export class PersonAvatar implements OnInit {
    @Input() entity?: IPersonAvatar;
    @Input() size: string = 'ion-avatar-lg';
    @Input() align?: string;
    @Input() loading?: boolean = false;
    @Input() className?: string;

    constructor(
        private modalController: ModalController
    ) {

    }

    ngOnInit() {
        //
    }

    public get imageUrl() {
        if (this.entity && this.entity.image && this.entity.image.path) {
            return `https://api.prestes.com/images/images/${this.entity.image.path}`;
        } else {
            return this.defaultImageUrl;
        }
    }

    public get defaultImageUrl() {
        return '../../../../../../assets/avatar.svg';
    }

    public onImgError(event) {
        event.target.src = this.defaultImageUrl;
    }
}
