import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class TitleService {

	private appTitle: string = 'Painel Personalize';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private title: Title
    ) {
	this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					break;
				}

				case event instanceof NavigationCancel:
				case event instanceof NavigationEnd: {
					this.setTitle();
					break;
				}
				case event instanceof NavigationError: {
					break;
				}
				default: {
					break;
				}
			}
		});
    }
    setTitle(title?: string) {
		if (title) {
			this.title.setTitle(title + ' - ' + this.appTitle);
			return;
		}

        var route = this.route.snapshot;

        while (route.firstChild) {
            route = route.firstChild;
        }

		if (route.data.title) {
			this.title.setTitle(route.data.title + ' - ' + this.appTitle);
		} else {
			this.title.setTitle(this.appTitle);
		}
	}
}
