import { AuthService } from 'pages/account/auth/shared/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'error-component',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    @Input() titlePage: string;
    @Input() statusCode: string;
    @Input() errorMessage: string;

    constructor(
        public auth: AuthService,
        public router: Router
    ) {}

    ngOnInit() {
        this.auth.checkAuth();
    }

    public back() {
        this.router.navigate(['/']);
    }

}
