import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { SessionService } from 'pages/account/auth/shared/session-service';

@Injectable({
    providedIn: 'root'
})
export class UnauthGuard implements CanActivate {

    constructor(
        private session: SessionService,
        private router: Router
    ) {}

    async canActivate(): Promise<boolean | UrlTree> {
        if (this.session.isValid) {
            return this.router.parseUrl('');
        }

        return true;
    }
}